<template>
  <step-lesson-layout :title="$t('module1.part3.lesson1.title')">
    <template v-slot:content>
      <lesson-video-block video="/videos/histoire-maprimerenov.mp4"
        subtitles="/subtitles/histoire-maprimerenov.vtt"
        :button-label="$t('module1.part3.lesson1.videoButton')"
        :poster="require('@/assets/module1/part3/poster-histoire-maprimerenov.svg')"
        v-on:next-tab="$emit('next-tab')">
        <template v-slot:bottom="slotProps">
          <div class="app-block">
            <div class="app-block-title">
              {{ $t('module1.part3.lesson1.majBlock.title') }}
            </div>
            <div class="app-block-content aides-list">
              <div class="intro">
                {{ $t('module1.part3.lesson1.majBlock.intro') }}
                <br><br>
                {{ $t('module1.part3.lesson1.majBlock.intro2') }}
              </div>
              <ul class="maj-list">
                <li>{{ $t('module1.part3.lesson1.majBlock.content1') }}</li>
                <li>{{ $t('module1.part3.lesson1.majBlock.content2') }}</li>
                <li>{{ $t('module1.part3.lesson1.majBlock.content3') }}</li>
              </ul>
            </div>
          </div>
          <p class="help" v-if="!slotProps.isClickedVideo">
            {{ $t('module1.part3.lesson1.help') }}
          </p>
          <app-button-layout class="next-button" v-else @click="$emit('next-tab')">
            {{ $t('global.navigation.resume') }}
          </app-button-layout>
        </template>
      </lesson-video-block>

    </template>
  </step-lesson-layout>
</template>

<script>
import StepLessonLayout from '@/components/training/StepLessonLayout'
import LessonVideoBlock from '@/components/training/LessonVideoBlock'
import AppButtonLayout from '@/components/buttons/AppButtonLayout'

export default {
  name: 'Module1Part3Lesson1',
  components: { LessonVideoBlock, StepLessonLayout, AppButtonLayout },
  emits: ['next-tab']
}
</script>

<style lang="scss" scoped>
.app-block {
  margin: $space-m $space-s;
}
.aides-list {
  .intro {
    margin-bottom: $space-m;
  }
  .maj-list {
    li + li {
      margin-top: $space-m;
    }
  }
}
.next-button {
  min-width: 320px;
  max-width: 100%;
  margin: 0 auto;
}
</style>
